<script setup lang="ts">

import { useAuthStore } from "~/store/auth/auth.store";
import { storeToRefs } from "pinia";

const user_icon = ref(null)

/** Expose Ref to Parent **/
defineExpose({ user_icon })

const authStore = useAuthStore()

const { user } = storeToRefs(authStore)

const name = computed(() => {
  const f = user.value?.firstname ? user.value?.firstname[0] : null
  const l = user.value?.lastname ? user.value?.lastname[0] : null
  if (authStore.isLoggedIn() && f && l) {
    return (f + l).toUpperCase()
  }

  return `-`
})

</script>

<template>
    <button ref="user_icon" class="small_med user_circle">{{ name }}</button>
</template>

<style scoped lang="scss">

.user_circle {
  @apply flex justify-center items-center;
  background: #E8D9FF;
  color: #8D43FF;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}

</style>